import React, { createContext, useContext, useState } from "react"
import { getLocalStorage, setLocalStorage, isBrowser } from "utils"
import { useMutation, useLazyQuery } from "@apollo/client"

import CREATE_WISHLIST_MUTATION from "apollo/mutation/CREATE_WISHLIST_MUTATION"
import UPDATE_WISHLIST_ITEMS_MUTATION from "apollo/mutation/UPDATE_WISHLIST_ITEMS_MUTATION"
import WISHLIST_QUERY from "apollo/query/WISHLIST_QUERY"

const WishlistContext = createContext({})

export const WishlistProvider = props => {
  const { userWishlistId } = getLocalStorage(`lmh-wishlist`) || {}

  const [wishlist, setWishlist] = useState(null)

  const [queryWishlist, queryWishlistRes] = useLazyQuery(WISHLIST_QUERY, {
    onCompleted: res => {
      const itemIds =
        res?.wishlist?.acf?.items &&
        res.wishlist.acf.items.map(item => item.itemId)

      setWishlist({ id: res?.wishlist?.id, items: itemIds || [] })
    },
  })

  const [createNewWishlist, createNewWishlistRes] = useMutation(
    CREATE_WISHLIST_MUTATION,
    {
      onCompleted: res => {
        const { createNewWishlist } = res || {}

        if (createNewWishlist?.id) {
          setLocalStorage(`lmh-wishlist`, {
            userWishlistId: createNewWishlist?.id,
          })

          queryWishlist({ variables: { id: createNewWishlist?.id } })
        }
      },
    }
  )

  const [updateWishlistItems, updateWishlistRes] = useMutation(
    UPDATE_WISHLIST_ITEMS_MUTATION,
    {
      onCompleted: res => {
        setWishlist(res?.updateWishlistItems)
      },
      onError: err => {
        console.log({ err })
      },
    }
  )

  const getWishlist = () => {
    const {
      called: newWishlistCalled,
      data: newWishlistData,
      loading: newWishlistLoading,
    } = createNewWishlistRes

    const {
      called: wishlistCalled,
      data: wishlistData,
      loading: wishlistLoading,
    } = queryWishlistRes

    if (
      !wishlist &&
      !userWishlistId &&
      !newWishlistCalled &&
      !newWishlistLoading &&
      !newWishlistData
    ) {
      // Existing wishlist not found, create new one

      isBrowser && createNewWishlist()
    } else if (
      userWishlistId &&
      !wishlist &&
      !wishlistLoading &&
      !wishlistCalled &&
      !wishlistData
    ) {
      queryWishlist({ variables: { id: userWishlistId } })
    }
  }

  !wishlist && getWishlist()

  const addItemToWishlist = itemId => {
    const itemAlreadyInWishlist =
      wishlist?.items && wishlist?.items?.find(item => item === itemId)

    if (!itemAlreadyInWishlist && wishlist) {
      // add to wishlist
      const newWishlistItems = [...wishlist.items, itemId].filter(
        item => item && item
      )

      updateWishlistItems({
        variables: { id: wishlist?.id, items: newWishlistItems },
      })
    }
  }

  const removeItemFromWishlist = itemId => {
    const itemAlreadyInWishlist =
      wishlist?.items && wishlist.items.find(item => item === itemId)

    if (itemAlreadyInWishlist && wishlist) {
      // remove from wishlist

      const newWishlistItems = wishlist?.items
        ? wishlist.items.filter(item => item !== itemId)
        : []

      updateWishlistItems({
        variables: { id: wishlist?.id, items: newWishlistItems },
      })
    }
  }

  return (
    <WishlistContext.Provider
      value={{
        wishlist,
        addItemToWishlist,
        removeItemFromWishlist,
        loading:
          createNewWishlistRes?.loading ||
          queryWishlistRes?.loading ||
          updateWishlistRes?.loading,
      }}
      {...props}
    />
  )
}

export const useWishlist = () => useContext(WishlistContext)

export default useWishlist
