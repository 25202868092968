import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  shadows: ["none"],
  typography: {
    fontFamily: "Work Sans, sans-serif",
    h1: {
      fontWeight: `lighter`,
      fontSize: "32px",
      color: colors.primary,
      [breakpoints.up("sm")]: {
        fontSize: "40px",
      },
      [breakpoints.up("md")]: {
        fontSize: "45px",
      },
      [breakpoints.up("lg")]: {
        fontSize: "50px",
      },
    },
    h2: {
      fontSize: "28px",
      color: colors.primary,
      [breakpoints.up("sm")]: {
        fontSize: "36px",
      },
    },
    h3: {
      fontSize: "24px",
      lineHeight: "36px",
      color: colors.primary,
    },
    h4: {
      fontSize: "22px",
      lineHeight: "32px",
      color: colors.primary,
    },
    h5: {
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 500,
      color: colors.primary,
    },
    h6: {},
    subtitle1: { lineHeight: "20px", fontWeight: 300 },
    subtitle2: { fontWeight: 300 },
    body1: {
      color: colors.primary,
      fontWeight: 300,
    },
    body2: {
      color: colors.primary,
      fontWeight: 300,
    },
    caption: { fontWeight: 300 },
    button: { fontWeight: 300 },
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white",
    },
    secondary: {
      main: colors.secondary,
    },
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        border: `1px solid ${colors.primary}`,
        "&:hover": {
          backgroundColor: colors.primary,
          color: `#fff`,
        },
      },
      root: {
        borderRadius: 0,
        padding: "8px 24px",
        fontWeight: 300,
      },
      outlined: {
        padding: "8px 24px",
      },
    },
  },
})
