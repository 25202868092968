import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"

import {
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core"

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

import { Edges } from "components"

const Accordion = props => {
  const { headline, items, ...rest } = props

  return (
    <Container {...rest} id={headline}>
      <Edges size="md">
        {headline && (
          <Link to={`#${headline}`}>
            <Typography variant="h2" gutterBottom>
              {headline}
            </Typography>
          </Link>
        )}
        {items && (
          <div>
            {items.map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </div>
        )}
      </Edges>
    </Container>
  )
}

export default Accordion

const Item = ({ headline, content, expanded }) => {
  const [open, setOpen] = useState(!!expanded)

  return (
    <MuiAccordion expanded={open} onChange={(_, val) => setOpen(val)}>
      <AccordionSummary
        css={css`
          && {
            padding: 0;
            .MuiAccordionSummary-content {
              align-items: center;
            }
          }
        `}
      >
        <ArrowForwardIosIcon
          color="primary"
          css={css`
            margin-right: 10px;
            transition: all 0.1s ease;
            ${open &&
              css`
                transform: rotate(90deg);
              `}
          `}
        />
        <Typography variant="h4" title>
          {headline}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {content && typeof content === "string" && Parser(content)}
      </AccordionDetails>
    </MuiAccordion>
  )
}

const Container = styled.div`
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 1rem;
  }
  margin: 25px 0;
  padding: 25px 0;
  @media (min-width: 768px) {
    margin: 40px 0;
    padding: 40px 0;
  }
`
