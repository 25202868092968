import { createGlobalStyle } from "styled-components"

import * as theme from "theme"

export default createGlobalStyle`

  body {
    background: #fff;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
`
