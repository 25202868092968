import React from "react"

// import app components
import FormContainer from "components/form"
import Edges from "components/edges"

const Form = props => {
  return (
    <Edges size="md">
      <FormContainer {...props} hideTitle />
    </Edges>
  )
}

export default Form
