import React from "react"
import Helmet from "react-helmet"

import { MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ApolloProvider } from "@apollo/client"
import { SnackbarProvider } from "notistack"

// import app components
import Layout from "./Layout"
import { SEO } from "components"
import { mui, GlobalStyles } from "theme"
import { StoreProvider } from "store"
import apolloClient from "apollo"
import { ShopifyProvider } from "shopify"
import { WishlistProvider } from "wishlist"

export default props => {
  const { pageContext } = props

  return (
    <MuiThemeProvider theme={mui}>
      <SnackbarProvider>
        <ApolloProvider client={apolloClient}>
          <ShopifyProvider>
            <WishlistProvider>
              <StoreProvider>
                <Helmet>
                  <html lang="en" />
                  <meta name="description" />
                  <meta
                    name="facebook-domain-verification"
                    content="or7fw31bi6xa12nvqqjzdlm55g0jbn"
                  />
                </Helmet>

                <SEO {...pageContext} />

                <CssBaseline />
                <GlobalStyles />

                <Layout {...props}>{props?.children}</Layout>
              </StoreProvider>
            </WishlistProvider>
          </ShopifyProvider>
        </ApolloProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  )
}
