import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import WhatsAppWidget from "react-whatsapp-widget"
import "react-whatsapp-widget/dist/index.css"

const Socials = props => {
  const { ...rest } = props

  const {
    themeOptions: {
      siteOptions: {
        socialMedia: {
          facebook,
          instagram,
          twitter,
          youtube,
          whatsApp: { displayMessage, phone, replayMessage },
        },
      },
    },
  } = useOptionsQuery()

  return (
    <Container {...rest} component="div">
      {facebook && (
        <Link
          href={facebook}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Facebook"
        >
          — Facebook
        </Link>
      )}

      {instagram && (
        <Link
          href={instagram}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Linkedin"
        >
          — Instagram
        </Link>
      )}

      {youtube && (
        <Link
          href={youtube}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Linkedin"
        >
          - YouTube
        </Link>
      )}

      {twitter && (
        <Link
          href={twitter}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Twitter"
        >
          - Twitter
        </Link>
      )}

      {phone && (
        <WhatsAppContainer>
          <WhatsAppWidget
            phoneNumber={phone}
            textReplyTime={replayMessage}
            message={displayMessage}
            companyName="LINDA MEYER HENTSCHEL"
            sendButton="Send"
          />
        </WhatsAppContainer>
      )}
    </Container>
  )
}

const Container = styled(Typography)`
  display: flex;
  justify-content: center;
`

const Link = styled.a`
  text-decoration: none;
  display: inline-block;
  margin: 0 25px;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`
const WhatsAppContainer = styled.div`
  z-index: 9;
  ._1bpcM {
    position: fixed !important;
  }
  ._2iWL7 {
    position: fixed !important;
  }
  ._3B7os {
    display: none;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    ._1bpcM {
      margin-right: 10px;
    }
    ._2iWL7 {
      right: 0;
      width: 320px;
    }
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              socialMedia {
                facebook
                instagram
                twitter
                youtube
                whatsApp {
                  fieldGroupName
                  displayMessage
                  phone
                  replayMessage
                }
              }
            }
          }
        }
      }
    `
  )
  return wp
}

export default Socials
