import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"

import fetch from "unfetch"
import moment from "moment"

import { getAuth, updateAuth } from "../auth"
import { setLocalStorage } from "../utils"

import { REFRESH_TOKEN_MUTATION } from "../apollo/mutation"

const uri = `${process.env.GATSBY_WP}/graphql`

const cache = new InMemoryCache()

const authLink = new ApolloLink((operation, forward) => {
  const { authToken, authExpiration, refreshToken } = getAuth() || {}

  const isExpired = moment().isBefore(moment(authExpiration, "x"))

  if (authToken && refreshToken && isExpired) {
    client
      .mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        variables: { refreshToken },
      })
      .then(response => {
        console.log({ tokenRefreshResponse: response })
        if (response?.refreshJwtAuthToken?.authToken) {
          updateAuth(response.refreshJwtAuthToken.authToken)
        }
      })
      .catch(err => {
        console.log({ tokenRefreshError: err })
        // delete auth keys from local storage
        setLocalStorage(process.env.GATSBY_STORAGE_KEY, null)
      })
  }

  operation.setContext(({ headers = {} }) => {
    // If token exists, add to headers
    if (authToken && !isExpired) {
      headers = {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      }
    }

    return { headers }
  })

  // move forward with operation
  return forward(operation)
})

const httpLink = createHttpLink({
  uri,
  fetch,
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink.concat(httpLink)]),
  cache,
  resolvers: {
    Mutation: {
      updateNetworkStatus: (_, { cache }) => {
        cache.writeData({ data: { isConnected: true } })
        return null
      },
    },
  },
})

export default client
