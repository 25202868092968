import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Typography } from "@material-ui/core"

import Edges from "components/edges"
import BackgroundImage from "components/backgroundImage"
import formatCurrencyString from "utils/formatCurrencyString"

const CompleteTheLook = props => {
  const { headline, items, ...rest } = props

  return (
    <Container {...rest}>
      <Edges size="lg">
        {headline && (
          <Typography variant="h2" component="h4" gutterBottom>
            {headline}
          </Typography>
        )}

        {items && (
          <Items>
            {items.map((item, index) => {
              return (
                <Item key={index} to={"/"}>
                  <ImageContainer>
                    {item?.overrideProductImage && item?.image && (
                      <BackgroundImage image={item?.image} />
                    )}

                    {!item?.overrideProductImage &&
                      item?.product?.product?.images && (
                        <BackgroundImage
                          image={item?.product?.product?.images[0]}
                        />
                      )}
                  </ImageContainer>

                  {item?.product?.product?.title && (
                    <Typography gutterBottom>
                      {item.product.product.title}
                    </Typography>
                  )}

                  {item?.product?.product?.priceRangeV2?.minVariantPrice
                    ?.amount && (
                    <Typography>
                      {
                        item?.product?.product?.priceRangeV2?.minVariantPrice
                          ?.currencyCode
                      }{" "}
                      {item?.product?.product?.priceRangeV2?.minVariantPrice
                        ?.amount &&
                        formatCurrencyString({
                          amount:
                            item?.product?.product?.priceRangeV2
                              ?.minVariantPrice?.amount,
                          currencyCode:
                            item?.product?.product?.priceRangeV2
                              ?.minVariantPrice?.currencyCode,
                        })}
                    </Typography>
                  )}
                </Item>
              )
            })}
          </Items>
        )}
      </Edges>
    </Container>
  )
}

export default CompleteTheLook

const Container = styled.div`
  margin: 60px 0;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0;
`

const Item = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  margin-bottom: 20px;
  width: 100%;
  @media (min-width: 800px) {
    width: calc((100% / 2) - 15px);
  }
  @media (min-width: 1400px) {
    width: calc((100% / 4) - 15px);
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 420px;
  margin-bottom: 10px;
`
