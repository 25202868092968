import React from "react"
import styled, { css } from "styled-components"

// import app components
import { Header, Footer, LightBox } from "components"
import CookieBanner from "components/cookieBanner"
import { LoginDialog } from "auth/components"
import { useAuthServices } from "auth"
import useShopify from "shopify"

import Popup from "../popup"

export default props => {
  useAuthServices(props?.location?.pathname)

  const { checkout } = useShopify()

  const frontRowAttribute =
    checkout?.customAttributes &&
    checkout.customAttributes.find(item => item.key === "frontRowCode")

  return (
    <>
      <Container>
        <div>
          <Header />
          <Main frontRowCode={frontRowAttribute?.value}>{props?.children}</Main>
        </div>
        <Footer />
      </Container>

      <LightBox />

      <LoginDialog />

      <Popup />

      <CookieBanner />
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: 80px;
`

const Main = styled.main`
  ${({ frontRowCode }) =>
    !!frontRowCode &&
    css`
      margin-top: 55px;
    `}

  > div:first-child {
    margin-top: 0;
  }
`
