import { gql } from "@apollo/client"

export const WISHLIST_QUERY = gql`
  query GetWishlist($id: ID!) {
    wishlist(id: $id) {
      title
      id
      databaseId
      acf {
        items {
          itemId
        }
      }
    }
  }
`

export default WISHLIST_QUERY
