import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useForm } from "react-hook-form"
import Parser from "html-react-parser"

import { Typography, TextField } from "@material-ui/core"

import { Button } from "components"

import useLazyFetch from "utils/fetch/useLazyFetch"
import useShopSettings from "components/shopifyProduct/useShopSettings"

const NewsletterSignup = props => {
  const { hideTitle, ...rest } = props

  const [state, setState] = useState(`default`)
  const { handleSubmit, register } = useForm()
  const { newsletterSignupHeadline } = useShopSettings()

  const [addSubscriber, { error, loading }] = useLazyFetch(
    `/.netlify/functions/add-subscriber`,
    {
      method: "POST",
      onCompleted: res => {
        setState(`success`)
      },
      onError: err => {
        if (err?.message && err.message.includes("already subscribed")) {
          setState(`error-already-subscribed`)
        } else {
          setState(`error`)
        }
      },
    }
  )

  const onSubmit = ({ email }) => {
    addSubscriber({ body: JSON.stringify({ email }) })
  }

  return (
    <Container {...rest}>
      {state === `default` ? (
        <>
          {!hideTitle && newsletterSignupHeadline && (
            <div
              css={css`
                margin-bottom: 10px;
              `}
            >
              {Parser(newsletterSignupHeadline)}
            </div>
          )}

          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              css={css`
                && {
                  @media (max-width: 599px) {
                    margin-bottom: 20px;
                  }
                }
              `}
              variant="filled"
              name="email"
              type="email"
              label="Email Address"
              required
              inputRef={register}
              fullWidth
            />

            <Button
              type="submit"
              loading={loading}
              variant="contained"
              color="secondary"
              css={css`
                padding: 12px 60px;
              `}
            >
              Subscribe
            </Button>
          </Form>
        </>
      ) : state === `success` ? (
        <>
          <Typography variant="h3" gutterBottom>
            THANK YOU!
          </Typography>
          <Typography gutterBottom>
            You have successfully signed up. Welcome to LMH world
          </Typography>
        </>
      ) : state === `error` ? (
        <>
          <Typography variant="h3" gutterBottom>
            ERROR
          </Typography>

          <Typography gutterBottom>
            Something went wrong. Please{" "}
            <span
              onClick={() => setState(`default`)}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              try again.
            </span>
          </Typography>
        </>
      ) : (
        state === `error-already-subscribed` && (
          <>
            <Typography variant="h3" gutterBottom>
              ALREADY SUBSCRIBED
            </Typography>

            <Typography gutterBottom>
              {error?.message} Please{" "}
              <span
                onClick={() => setState(`default`)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                try again.
              </span>
            </Typography>
          </>
        )
      )}
    </Container>
  )
}

export default NewsletterSignup

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 850px;
  max-width: 90%;
  margin: 30px auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`
