const colors = {
  primary: "#686869",
  secondary: "#2a2a2a",
  background: {
    light: "#fff",
    dark: "#f6f6f4"
  },
  text: {
    light: "#405C5E",
    dark: "#232323"
  },
  success: "#00A497",
  error: "#e24141"
}

export default colors
