import React, { useState } from "react"
import styled from "styled-components"
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core"
import { Link } from "gatsby"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router"

// import app components
import { Button, Spacer, Textarea } from "components"
import { setCookie, getCookie } from "utils/cookie"

const cookieName = process.env.GATSBY_COOKIE_NAME

const CookieBanner = () => {
  const [settings, setSettings] = useState(false)

  const cookiePreference =
    typeof window !== "undefined" && getCookie(cookieName)

  let defaultValue = "enabled"

  if (cookiePreference && cookiePreference === "false") {
    defaultValue = "disabled"
  }

  const location = useLocation()

  const [profiling, setProfiling] = useState(defaultValue)
  const [hideDialog, setHideDialog] = useState(cookiePreference === "true")

  const text = `We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.`
  const privacyButton = `Cookie Policy`
  const privacyLink = `/cookie-policy`
  const acceptButton = `Accept`
  const settingsButton = `Settings`
  const saveButton = `Save`

  const settingsText = `
    <h3>Privacy Overview</h3>
    <p>This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.</p>
    
    <strong>Necessary</strong>
    <p>Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.</p>
    
    <strong>Non-Necessary</strong>
    <p>Any cookies that may not be particularly necessary for the website to function and is used specifically to collect user personal data via analytics, ads, other embedded contents are termed as non-necessary cookies. It is mandatory to procure user consent prior to running these cookies on your website.</p>
  `

  const handleAccept = () => {
    setCookie(cookieName, true, 30)
    setHideDialog(true)
    setSettings(false)
    initializeAndTrack(location)
  }

  const handleSave = () => {
    const value = profiling === "enabled" ? true : false
    setCookie(cookieName, value, 30)
    setHideDialog(true)
    setSettings(false)
  }

  const handleOpenSettings = () => setSettings(true)

  return (
    <>
      {!hideDialog && typeof window !== "undefined" && (
        <Container>
          <Grid>
            <Legal children={text} onClick={handleOpenSettings} />

            <Action>
              <Link to={privacyLink}>
                <Settings children={privacyButton} />
              </Link>
              <Spacer ml={15}>
                <Settings
                  children={settingsButton}
                  onClick={() => setSettings(true)}
                />
              </Spacer>
              <Spacer ml={15}>
                <Button
                  onClick={handleAccept}
                  children={acceptButton}
                  variant="contained"
                  color="secondary"
                />
              </Spacer>
            </Action>
          </Grid>
        </Container>
      )}

      <Dialog
        open={settings}
        onClose={() => setSettings(false)}
        fullWidth
        maxWidth="md"
      >
        <StyledDialogContent>
          <Textarea content={settingsText} />
        </StyledDialogContent>
        <DialogActions>
          <StyledRadioGroup
            aria-label="profiling"
            value={profiling}
            onChange={e => setProfiling(e.target.value)}
          >
            <FormControlLabel
              value={"disabled"}
              control={<Radio />}
              label="Disable"
            />
            <FormControlLabel
              value={"enabled"}
              control={<Radio />}
              label="Enable"
            />
          </StyledRadioGroup>

          <StyledButton
            onClick={handleSave}
            children={saveButton}
            variant="contained"
            color="secondary"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

const Container = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 -1px 10px 0 rgb(172 171 171);
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 960px) {
    flex-wrap: nowrap;
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media (min-width: 960px) {
    margin-top: 0;
  }
`

const Legal = styled(Typography)`
  && {
    width: 100%;
    margin-right: 20px;
    font-size: 13px;
    text-transform: none;

    @media (min-width: 960px) {
      max-width: calc(100% - 320px);
    }
  }
`

const Settings = styled(Typography)`
  font-size: 12px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const StyledButton = styled(Button)`
  && {
    .btn-children {
      font-size: 12px;
    }
  }
`

const StyledDialogContent = styled(DialogContent)`
  && {
    p {
      text-transform: none;
    }
  }
`

const StyledRadioGroup = styled(RadioGroup)`
  && {
    flex-direction: row;
  }
`

export default CookieBanner
