import React from "react"
import styled from "styled-components"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BannerImage = props => {
  const { className, desktopImage, mobileImage } = props
  const gatsbyDesktopImage = desktopImage && getImage(desktopImage.localFile)
  const gatsbyMobileImage = mobileImage && getImage(mobileImage.localFile)

  return (
    <Container className={className}>
      {gatsbyDesktopImage && (
        <GatsbyImage
          className="banner-image__desktop"
          image={gatsbyDesktopImage}
          alt={desktopImage?.altText}
        />
      )}

      {gatsbyMobileImage && (
        <GatsbyImage
          className="banner-image__mobile"
          image={gatsbyMobileImage}
          alt={mobileImage?.altText}
        />
      )}
    </Container>
  )
}

export default BannerImage

const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  .banner-image {
    &__desktop {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    &__mobile {
      display: block;
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
`
