import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
// import Slider from "react-slick"
// import ArrowBack from "@material-ui/icons/ArrowBackIos"
// import ArrowForward from "@material-ui/icons/ArrowForwardIos"
import { IconButton } from "@material-ui/core"

// import app components
import { Socials } from "components"
import { colors } from "theme"

import InstagramFeed from "react-ig-feed"
import "react-ig-feed/dist/index.css"

// const StyledIconButton = styled(IconButton)`
//   && {
//     background: rgba(255, 255, 255, 0.4);

//     &.slick-prev {
//       svg {
//         transform: translateX(4px);
//       }
//     }
//   }
// `

// const settings = {
//   dots: false,
//   arrows: true,
//   autoplay: true,
//   autoplaySpeed: 3000,
//   speed: 500,
//   slidesToShow: 6,
//   slidesToScroll: 1,
//   infinite: true,
//   nextArrow: (
//     <StyledIconButton>
//       <ArrowForward />
//     </StyledIconButton>
//   ),
//   prevArrow: (
//     <StyledIconButton>
//       <ArrowBack />
//     </StyledIconButton>
//   ),
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 5,
//       },
//     },
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 4,
//       },
//     },
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 3,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//   ],
// }

const InstagramSlider = () => {
  // const { nodes } = useInstagramQuery()

  return (
    <>
      {process.env.GATSBY_INSTAGRAM_KEY && (
        <Container>
          {/* <InstagramFeed token={process.env.GATSBY_INSTAGRAM_KEY} counter="6" /> */}

          {/* {!!nodes && (
          <Slider {...settings}>
          {nodes.map(({ permalink, localImage, caption }, index) => {
            return (
              <Item key={index} href={permalink} target="_blank">
              <ImageContainer>
              <BackgroundImage
              alt={caption}
              image={{ localFile: localImage }}
              position="absolute"
              animated
              />
              </ImageContainer>
              </Item>
              )
            })}
            </Slider>
          )} */}
        </Container>
      )}

      <SocialsContainer>
        <Socials />
      </SocialsContainer>
    </>
  )
}

const Container = styled.div`
  background: ${colors.background.dark};
  /* height: 220px; */
  /* 
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 220px;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  } */
`

// const Item = styled.a`
//   height: 220px;
//   padding: 20px 10px;
//   cursor: pointer;
//   text-decoration: none;
// `

// const ImageContainer = styled.div`
//   position: relative;
//   height: 180px;
// `

const SocialsContainer = styled.div`
  padding: 20px 0;
  background: ${colors.background.dark};
  margin-bottom: 20px;
`

// const useInstagramQuery = () => {
//   const { allInstagramContent } = useStaticQuery(
//     graphql`
//       query {
//         allInstagramContent(limit: 20) {
//           nodes {
//             caption
//             permalink
//             localImage {
//               childImageSharp {
//                 fluid(maxHeight: 300, maxWidth: 300, quality: 90) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     `
//   )
//   return allInstagramContent
// }

export default InstagramSlider
