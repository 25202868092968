import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import HamburgerMenu from "react-hamburger-menu"

import { LinearProgress, Typography, IconButton } from "@material-ui/core"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
// import app components
import { DesktopMenu, MobileMenu, Edges } from "components"
import { useStore } from "store"
import useShopify from "shopify"
import FrontRowBar from "./FrontRowBar"

import Logo from "icons/linda_logo.svg"
import ShoppingCartIcon from "icons/lmh_shopping_icon.svg"

import * as theme from "theme"

export default props => {
  const { path } = props

  const { checkout } = useShopify()

  const [
    {
      appState: { progress, menu },
    },
    dispatch,
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const quantities =
    !!checkout?.lineItems && checkout?.lineItems.map(i => i.quantity)
  const cartItemCount = checkout ? quantities.reduce(reducer, 0) : 0

  return (
    <>
      <Container position="static" id="app-header">
        <Edges size="lg">
          <Grid>
            <Grid>
              <LogoContainer>
                <Link
                  to="/"
                  onClick={() => dispatch({ type: "SET_MENU", payload: false })}
                >
                  <Logo />
                </Link>
              </LogoContainer>

              <DesktopMenu
                className="desktop-menu"
                style={{ marginRight: "10px" }}
              />
            </Grid>

            <Grid>
              <IconButton component={Link} to="/wishlist">
                <FavoriteBorderIcon />
              </IconButton>

              <CartButton component={Link} to="/shopping-bag">
                <CartCount visible={!!cartItemCount}>
                  <Typography className="cart-item-count">
                    {cartItemCount}
                  </Typography>
                </CartCount>
                <ShoppingCartIcon />
              </CartButton>

              <HamburgerMenuContainer
                onClick={() => dispatch({ type: "TOGGLE_MENU" })}
              >
                <HamburgerMenu
                  color={theme.colors.primary}
                  isOpen={menu}
                  width={18}
                  height={15}
                  strokeWidth={1}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Grid>
          </Grid>
        </Edges>
      </Container>
      <FrontRowBar />

      <MobileMenu />

      {progress && <StyledLinearProgress color="primary" />}
    </>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  padding: 10px 0;
  display: flex;
  align-items: center;
  z-index: 999;
  height: 80px;
  width: 100%;
`

const Grid = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desktop-menu {
    @media (max-width: 1023px) {
      display: none;
    }
  }
`

const LogoContainer = styled(Typography)`
  a {
    display: flex;
    text-decoration: none;
  }

  svg {
    width: 175px;
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  transform: translateX(15px);
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
`

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
`
const CartButton = styled(IconButton)`
  position: relative;
  display: block;
  color: #757575 !important;
  svg {
    width: 24px;
    height: 24px;
  }
  /* @media (min-width: 800px) {
    margin-left: 10px;
  } */
`
const CartCount = styled.div`
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: -3px;
  right: -3px;
  background: ${theme.colors.primary};
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  .cart-item-count {
    font-size: 11px;
    color: white;
    font-weight: 500;
  }
`
