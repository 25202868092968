import { gql } from "@apollo/client"

export const CREATE_WISHLIST_MUTATION = gql`
  mutation CreateWishlistMutation {
    createNewWishlist(input: { clientMutationId: "uniqueid" }) {
      id
      databaseId
    }
  }
`
export default CREATE_WISHLIST_MUTATION
