import React from "react"
import styled from "styled-components"

// import app components
import { Edges, Textarea, Button, Spacer } from "components"

const Columns = props => {
  const { columns, size, items } = props

  return (
    <Container>
      <Edges size={size || "xl"}>
        <Items>
          {items &&
            items.map((o, i) => {
              return (
                <Item key={i} columns={columns}>
                  {o.text && <Textarea content={o.text} />}
                  {o.button && (
                    <Spacer mt={20} mb={40}>
                      <Button
                        to={o.button.url}
                        target={o.button.target}
                        children={o.button.title}
                        variant={o.buttonStyle}
                        color="secondary"
                      />
                    </Spacer>
                  )}
                </Item>
              )
            })}
        </Items>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding: 10px 0;
  margin: 10px 0;

  @media (min-width: 768px) {
    padding: 15px 0;
    margin: 20px 0;
  }
`

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Item = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 640px) {
    width: calc(100% / 2 - 30px);
  }

  @media (min-width: 960px) {
    ${({ columns }) => parseInt(columns) > 2 && "width: calc(100% / 3 - 30px)"};
  }

  @media (min-width: 1280px) {
    ${({ columns }) => parseInt(columns) > 3 && "width: calc(100% / 4 - 30px)"};
  }
`

export default Columns
