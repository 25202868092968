import React from "react"
import styled, { css } from "styled-components"

// import app components
import { Edges, Textarea } from "components"

import BannerImage from "./BannerImage"

const TextImageColumns = props => {
  const {
    columns,
    imagePlacement,
    items,
    desktopBannerImage,
    textAlignment,
  } = props

  return (
    <Container imagePlacement={imagePlacement} textAlignment={textAlignment}>
      <Edges size={"xl"}>
        <Items>
          {items &&
            items.map((item, i) => {
              const { text, mobileImage } = item
              return (
                <Item
                  key={i}
                  columns={columns || 2}
                  imagePlacement={imagePlacement}
                  textAlignment={textAlignment}
                >
                  <div
                    css={css`
                      padding: 25px 0;
                      margin: 25px 0;

                      @media (min-width: 768px) {
                        padding: 50px 0;
                        margin: 50px 0;
                      }
                    `}
                  >
                    {text && <Textarea content={text} />}
                  </div>
                  <BannerImage mobileImage={mobileImage} />
                </Item>
              )
            })}
        </Items>
      </Edges>
      <BannerImage desktopImage={desktopBannerImage} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;

  flex-direction: column;

  ${({ imagePlacement }) =>
    imagePlacement === "above" &&
    css`
      flex-direction: column-reverse;
    `}
`

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Item = styled.div`
  display: flex;

  flex-direction: ${props =>
    props.textAlignment === "bottom" ? "column-reverse" : "column"};

  ${({ imagePlacement }) =>
    imagePlacement === "above" &&
    css`
      flex-direction: ${props =>
        props.textAlignment === "bottom" ? "column-reverse" : "column"};
    `}

  width: 100%;

  @media (min-width: 768px) {
    width: calc(100% / 2 - 30px);
  }

  @media (min-width: 960px) {
    ${({ columns }) => parseInt(columns) > 2 && "width: calc(100% / 3 - 30px)"};
  }

  @media (min-width: 1280px) {
    ${({ columns }) => parseInt(columns) > 3 && "width: calc(100% / 4 - 30px)"};
  }
`

export default TextImageColumns
