import getSymbolFromCurrency from "currency-symbol-map"

export const formatCurrencyString = ({
  amount,
  currencyCode,
  displaySymbol = true,
}) => {
  // console.log({ amount })
  const number = Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const symbol = currencyCode && getSymbolFromCurrency(currencyCode)

  if (displaySymbol && !!symbol) {
    return `${symbol}${number}`
  } else {
    return number
  }
}

export default formatCurrencyString
