import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core"

import { Textarea } from "components"

import CloseIcon from "@material-ui/icons/Close"
import NewsletterSignup from "../footer/NewsletterSignup"

import LMHLogo from "icons/LMH-monogram-dark-grey01.svg"
import { getLocalStorage, setLocalStorage } from "utils"
import * as theme from "theme"

const storageKey = `lmh-popper`

const Popup = () => {
  const [open, setOpen] = useState(false)

  const {
    popupEnabled,
    popupHeadline,
    popupContent,
    popupBottomText,
  } = usePopupQuery()

  const storage = getLocalStorage(storageKey)

  if (!storage?.closed && !open) {
    setOpen(true)
  }

  const handleClose = () => {
    setLocalStorage(storageKey, { closed: true })
    setOpen(false)
  }

  if (!popupEnabled) {
    return null
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      css={css`
        .MuiDialog-paperWidthSm {
          max-width: 650px;
        }
      `}
    >
      {popupHeadline && (
        <DialogTitle
          css={css`
            padding-bottom: 0;
            > h2 {
              display: flex;
              justify-content: flex-end;
            }
          `}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent>
        {popupHeadline && <Headline gutterBottom>{popupHeadline}</Headline>}

        <GraphicContainer>
          <Line />
          <LMHLogo />
          <Line />
        </GraphicContainer>

        {popupContent && <Textarea content={popupContent} />}

        <NewsletterSignup
          hideTitle
          css={css`
            max-width: 100%;
          `}
        />

        {popupBottomText && <Textarea content={popupBottomText} />}
      </DialogContent>
    </Dialog>
  )
}

export default Popup

const Headline = styled(props => <Typography variant="h2" {...props} />)`
  text-align: center;
`

const GraphicContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0 30px;

  > svg {
    width: 100px;
    margin: 0 20px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 3px;
  background: ${theme.colors.primary};
`

const usePopupQuery = () => {
  const {
    wp: {
      themeOptions: { siteOptions },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        themeOptions {
          siteOptions {
            popupEnabled
            popupHeadline
            popupContent
            popupBottomText
          }
        }
      }
    }
  `)

  return siteOptions
}
