// import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const useShopSettings = () => {
  const {
    wp: {
      themeOptions: { siteOptions },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        themeOptions {
          siteOptions {
            agreementText
            addToCartButtonLabels {
              default
              preorder
              soldOut
            }
            preorderText
            cartItemPreorderText
            cartItemFrontRowText
            freeShippingCaption
            shippingInfoLink {
              title
              target
              url
            }
            productTooltipMinimum
            frontRowAdditionalText
            frontRowSuccessMessage
            frontRowInvalidCodeMessage
            frontRowGenericErrorMessage
            frontRowInputPlaceholderText
            newsletterSignupHeadline
          }
        }
      }
    }
  `)

  return siteOptions
}

export default useShopSettings
