import "./src/theme/globals.css"

export const onClientEntry = async () => {
  if (typeof window !== `undefined`) {
    // Smooth scrolling enabled for all anchor links pointing to #ids
    require("smooth-scroll")('a[href*="#"]', {
      header: "#app-header",
    })
  }
}

export const onInitialClientRender = () => {
  // Scroll to location.hash on page load
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const hashElement = document.getElementById(
      window.location.hash.replace("#", "")
    )
    if (!!hashElement) {
      hashElement.scrollIntoView()
      window.scrollBy(0, -120)
    }
  }
}
