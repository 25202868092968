import React from "react"
import styled from "styled-components"

// import app components
import Socials from "components/socials"

const SocialLinks = props => {
  return (
    <SocialsContainer>
      <Socials />
    </SocialsContainer>
  )
}

const SocialsContainer = styled.div`
  padding: 20px 0;
  background: #f4f4f4;
`

export default SocialLinks
