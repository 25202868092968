import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
// import MenuItems from "./MenuItems"

import { Typography } from "@material-ui/core"

// import app components
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"

const LegalMenu = props => {
  const items = useMenuItems("legal-menu")

  return (
    <Menu {...props}>
      {items &&
        items.map(({ id, url, label }) => (
          <MenuItem key={id}>
            <MenuLink to={getUrlPath(url)}>
              <Typography
                color="inherit"
                component="div"
                variant="body2"
                children={Parser(label)}
              />
            </MenuLink>
          </MenuItem>
        ))}
    </Menu>
  )
}

export default LegalMenu

const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }

  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  width: 100%;
  position: relative;
  text-align: center;

  @media (min-width: 768px) {
    width: auto;
  }
`

const MenuLink = styled(Link)`
  display: block;
  padding: 20px 10px;
  color: inherit;

  @media (min-width: 768px) {
    display: inline-block;
    margin-left: 20px;
  }
`
