import isBrowser from "./isBrowser"

import getLocalStorage from "utils/getLocalStorage"

/**
 *
 * @param {string} key
 * @param {any} value
 */

const updateLocalStorage = (key, value) => {
  if (!isBrowser) {
    return
  }

  if (!key || !value || typeof value !== "object") {
    console.warn("updateLocalStorage requires a key and an object")
    return
  }

  const existingValues = getLocalStorage(key)
  const updatedValues = { ...existingValues, ...value }

  window.localStorage.setItem(key, JSON.stringify(updatedValues))
}

export default updateLocalStorage
