exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-shopping-bag-js": () => import("./../../../src/pages/shopping-bag.js" /* webpackChunkName: "component---src-pages-shopping-bag-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-archive-collections-js": () => import("./../../../src/templates/archive/collections.js" /* webpackChunkName: "component---src-templates-archive-collections-js" */),
  "component---src-templates-archive-posts-js": () => import("./../../../src/templates/archive/posts.js" /* webpackChunkName: "component---src-templates-archive-posts-js" */),
  "component---src-templates-single-collection-js": () => import("./../../../src/templates/single/collection.js" /* webpackChunkName: "component---src-templates-single-collection-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single/product.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

