import React from "react"

// import flexible content modules
import Form from "./Form"
import Text from "./Text"
import Hero from "./Hero"
import TwoImages from "./TwoImages"
import Columns from "./Columns"
import InstagramSlider from "./InstagramSlider"
import CompleteTheLook from "./CompleteTheLook"
import BannerImage from "./BannerImage"
import Accordion from "./Accordion"
import SocialLinks from "./SocialLinks"
import TextImageColumns from "./TextImageColumns"

export default props => {
  const { rows, data } = props

  if (!!rows) {
    return rows
      .filter(o => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Form,
          Text,
          Hero,
          TwoImages,
          Columns,
          InstagramSlider,
          CompleteTheLook,
          BannerImage,
          SocialLinks,
          Accordion,
          TextImageColumns,
        }
        const Component = components[type]
        return (
          Component && (
            <Component key={index} first={index === 0} {...rowData} {...data} />
          )
        )
      })
  }
}
