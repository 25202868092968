import React from "react"
import Dialog from "@material-ui/core/Dialog"

import { LoginForm } from "auth/components"
import { useStore } from "store"

export default props => {
  const [
    {
      appState: { loginDialog }
    },
    dispatch
  ] = useStore()

  if (loginDialog) {
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open={loginDialog}
        onClose={() => dispatch({ type: "SET_LOGIN_DIALOG", payload: false })}
        {...props}
      >
        <LoginForm />
      </Dialog>
    )
  } else {
    return null
  }
}
