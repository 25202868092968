import { gql } from "@apollo/client"

export const UPDATE_WISHLIST_ITEMS_MUTATION = gql`
  mutation UpdateWishlistItems($id: ID!, $items: [String]) {
    updateWishlistItems(
      input: { id: $id, clientMutationId: "updateWishlistItems", items: $items }
    ) {
      databaseId
      id
      items
    }
  }
`
export default UPDATE_WISHLIST_ITEMS_MUTATION
