import React from "react"
import styled, { keyframes } from "styled-components"

import useShopify from "shopify"

import * as theme from "theme"

const FrontRowBar = props => {
  const { ...rest } = props

  const { checkout } = useShopify()

  const frontRowAttribute =
    checkout?.customAttributes &&
    checkout.customAttributes.find(item => item.key === "frontRowCode")

  if (frontRowAttribute?.value) {
    return (
      <Container {...rest}>
        <Banner />
      </Container>
    )
  } else {
    return null
  }
}

export default FrontRowBar

const Container = styled.div`
  position: fixed;
  z-index: 99;
  width: 100%;
  background: ${theme.colors.primary};
  color: #fff;
`

const slide = keyframes`
 from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(calc(-100vw * 2), 0, 0);
  }
`

const Banner = styled.div`
  background: url("/images/front_row_graphic.png");
  background-repeat: repeat-x;
  background-size: contain;
  height: 40px;
  width: calc(100vw * 3);
  animation: ${slide} 60s linear infinite;
`
