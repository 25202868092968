import React, { createContext, useContext, useState } from "react"
// must load the large unoptimized version to be able to customize the products query
import shopifyBuy from "shopify-buy"

import { useSnackbar } from "notistack"

import { getLocalStorage, updateLocalStorage } from "utils"
import config from "./config"
import { isBrowser } from "utils"

import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

const client =
  isBrowser &&
  shopifyBuy.buildClient({
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    domain: process.env.GATSBY_SHOPIFY_SITE_URL,
  })

const ShopifyContext = createContext({})

export const ShopifyProvider = props => {
  const { enqueueSnackbar } = useSnackbar()

  const { checkoutId: existingCheckoutId } =
    getLocalStorage(config.storageKey) || {}

  const [checkout, setCheckout] = useState(null)

  const createNewCheckout = async client => {
    if (!client) {
      return null
    }
    return await client.checkout.create().then(checkout => {
      updateLocalStorage(config.storageKey, { checkoutId: checkout.id })
      setCheckout(checkout)
      return checkout
    })
  }

  // fetch checkout to see if it still exists or if it expired
  const getCheckout = async () => {
    if (!client) {
      return null
    }
    return !!existingCheckoutId
      ? await client.checkout
          .fetch(existingCheckoutId)
          .then(checkout => {
            if (checkout?.completedAt) {
              // checkout already completed, create a new one instead
              createNewCheckout(client)
            }

            updateLocalStorage(config.storageKey, { checkoutId: checkout.id })
            setCheckout(checkout)
            return checkout
          })
          .catch(err => {
            console.log({ err })
            // failed to fetch existing checkout -> create new checkout instead
            createNewCheckout(client)
          })
      : await createNewCheckout(client)
  }

  !checkout && getCheckout()

  const addLineItem = ({
    notification,
    variant,
    quantity,
    onCompleted,
    onError,
  }) => {
    if (!client) {
      console.warn("shopify client not found")
      return null
    }

    const lineItemsToAdd = [
      {
        variantId: variant.shopifyId || variant.id,
        quantity,
      },
    ]
    client.checkout
      .addLineItems(checkout.id, lineItemsToAdd)
      .then(checkout => {
        updateLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)

        notification && enqueueSnackbar(notification)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)
      })
      .catch(error => {
        // sometimes removing many items from cart too fast can cause an error -> refetch checkout if this happens
        getCheckout()
        onError && typeof onError === `function` && onError(error)
      })
  }

  const removeLineItem = ({
    notification,
    lineItemId,
    onCompleted,
    onError,
  }) => {
    if (!client) {
      console.warn("shopify client not found")
      return null
    }
    client.checkout
      .removeLineItems(checkout.id, [lineItemId])
      .then(checkout => {
        updateLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)

        notification && enqueueSnackbar(notification)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)
      })
      .catch(error => {
        onError && typeof onError === `function` && onError(error)
      })
  }

  const updateLineItem = ({
    notification,
    lineItemId,
    quantity,
    onCompleted,
    onError,
  }) => {
    if (!client) {
      console.warn("shopify client not found")
      return null
    }

    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    client.checkout
      .updateLineItems(checkout.id, lineItemsToUpdate)
      .then(checkout => {
        notification && enqueueSnackbar(notification)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)

        updateLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)
      })
      .catch(error => {
        onError && typeof onError === `function` && onError(error)
      })
  }

  const changeCurrencyCode = ({ newCurrencyCode, onCompleted, onError }) => {
    if (!client) {
      return null
    }
    if (!newCurrencyCode) {
      console.warn("newCurrencyCode required for changeCurrencyCode")
      return null
    }

    let lineItems = []
    // create new array for checkout lineItemInput
    if (checkout?.lineItems && checkout.lineItems.length > 0) {
      lineItems = checkout.lineItems.map(item => {
        return {
          variantId: item.variant.id,
          quantity: item.quantity,
        }
      })
    }

    client.checkout
      .create({
        presentmentCurrencyCode: newCurrencyCode,
        lineItems,
      })
      .then(checkout => {
        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)

        updateLocalStorage(config.storageKey, { checkoutId: checkout.id })

        setCheckout(checkout)
      })
      .catch(error => {
        onError && typeof onError === `function` && onError(error)
      })
  }

  const [products, setProducts] = useState(null)
  // Build a custom products query using the unoptimized version of the SDK

  // Call the send method with the custom products query
  isBrowser &&
    !products &&
    client &&
    client.product
      .fetchAll()
      .then(products => {
        setProducts(products)
      })
      .catch(err => {
        console.log("Failed to fetch products from store.", { err })
      })

  const getVariantAvailability = variantId => {
    let foundVariant = null

    products &&
      products.forEach(product => {
        product?.variants &&
          product.variants.forEach(variant => {
            if (variant.id === variantId) {
              foundVariant = variant
            }
          })
      })

    return foundVariant
  }

  const addCheckoutAttributes = customAttributes => {
    if (!client) {
      return null
    }
    if (!customAttributes) {
      console.warn("customAttributes required for addCheckoutAttributes")
      return null
    }

    client.checkout
      .updateAttributes(checkout.id, { customAttributes })
      .then(checkout => {
        setCheckout(checkout)
      })
  }

  const addOrderNote = orderNote => {
    if (!client) {
      return null
    }
    if (!orderNote) {
      console.warn("orderNote required for addOrderNote")
      return null
    }

    client.checkout
      .updateAttributes(checkout.id, { note: orderNote })
      .then(checkout => {
        setCheckout(checkout)
      })
  }

  const VERIFY_CODE = gql`
    mutation VerifyCode($code: String!) {
      verifyFrontRowCode(
        input: { frontRowCode: $code, clientMutationId: $code }
      ) {
        isValid
        frontRowCode
      }
    }
  `

  const [verifyCode, { loading, called }] = useMutation(VERIFY_CODE, {
    onCompleted: data => {
      // create new checkout if code is not valid
      if (!data?.verifyFrontRowCode?.isValid) {
        createNewCheckout(client)
      }
    },
  })

  // check if checkout has FR code and verify it's still valid
  const frontRowAttribute =
    checkout?.customAttributes &&
    checkout.customAttributes.find(item => item.key === "frontRowCode")

  if (checkout && frontRowAttribute?.value && !called && !loading) {
    // verify code is valid
    verifyCode({ variables: { code: frontRowAttribute.value } })
  }

  const getLiveProduct = shopifyId => {
    const liveProduct =
      products && products?.find?.(product => product.id === shopifyId)

    return liveProduct
  }
  const getLiveVariant = ({ shopifyId, variantId }) => {
    const liveProduct = getLiveProduct(shopifyId)

    // console.log(liveProduct)
    const liveVariant = liveProduct?.variants?.find?.(
      variant => variant.id === variantId
    )
    return liveVariant
  }

  return (
    <ShopifyContext.Provider
      value={{
        client,
        checkout,
        addLineItem,
        removeLineItem,
        updateLineItem,
        changeCurrencyCode,
        getVariantAvailability,
        addCheckoutAttributes,
        addOrderNote,
        getLiveVariant,
      }}
      {...props}
    />
  )
}

export const useShopify = () => useContext(ShopifyContext)

export default useShopify
