import React from "react"
import styled from "styled-components"

// import app components
import { BackgroundImage } from "components"

const TwoImages = props => {
  const { image1, image2, variant } = props

  return (
    <Container>
      <Image1Container variant={variant}>
        <BackgroundImage image={image1} />
      </Image1Container>

      <Image2Container>
        <BackgroundImage image={image2} />
      </Image2Container>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  border: 10px solid #fff;

  @media (min-width: 768px) {
    border: none;
  }
`

const Image1Container = styled.div`
  position: relative;
  width: 50%;
  height: 200px;
  border: 10px solid #fff;

  @media (min-width: 768px) {
    margin-left: 10%;
    margin-right: 10%;
    width: 30%;
    height: 80vh;
    order: ${({ variant }) => (variant === "variant1" ? 1 : 3)};
    border: none;
  }
`

const Image2Container = styled.div`
  position: relative;
  width: 50%;
  height: 200px;
  border: 10px solid #fff;

  @media (min-width: 768px) {
    width: 50%;
    height: 100vh;
    order: 2;
    border: none;
  }
`

export default TwoImages
