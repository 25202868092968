import React from "react"
import styled, { css } from "styled-components"
import { useScrollYPosition } from "react-use-scroll-position"

import useWindowSize from "utils/useWindowSize"
import ArrowUp from "icons/chevron-down.svg"
import { colors } from "theme"

export default function ScrollToTop(props) {
  const scrollY = useScrollYPosition()
  const { height } = useWindowSize()
  const scrollPercentage = Math.floor((scrollY / height) * 100)

  return (
    <Container active={scrollPercentage > 20} {...props}>
      <ToTop href="#top">
        <ArrowUp className="arrow-up" />
      </ToTop>
    </Container>
  )
}

const Container = styled.div`
  overflow: hidden;
  display: block;
  transition: opacity 0.4s ease-in-out;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  color: ${colors.primary};
  position: fixed;
  bottom: 10px;
  right: 10px;

  ${props =>
    props.active &&
    css`
      pointer-events: all;
      opacity: 1;
    `}

  .arrow-up {
    transform: rotate(180deg);
  }
`
const ToTop = styled.a`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${colors.primary};
  color: inherit;

  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: ${colors.primary};
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }
`
