import React, { useState } from "react"
import styled, { css } from "styled-components"
import ReactPlayer from "react-player/lazy"
import { IconButton } from "@material-ui/core"

// import app components
import { BackgroundImage } from "components"
import PlayIcon from "icons/play.svg"

import { colors } from "theme"

const Hero = props => {
  const {
    useDifferentVideoForMobile,
    videoType,
    youtubeUrl,
    image,
    video,
    autoplay,
    keepAspectRatio,
    mobileVideo,
  } = props

  const [play, setPlay] = useState(!!autoplay)

  const videoUrl =
    videoType === "youtube_url" ? youtubeUrl : video?.localFile?.publicURL

  const mobileVideoUrl =
    useDifferentVideoForMobile &&
    (mobileVideo?.videoType === "youtube_url"
      ? mobileVideo?.youtubeUrl
      : mobileVideo?.video?.localFile?.publicURL)

  const isResponsiveEmbed = !!(videoType === "youtube_url" || keepAspectRatio)

  const isMobileResponsiveEmbed = !!(
    mobileVideo?.videoType === "youtube_url" || mobileVideo?.keepAspectRatio
  )

  return (
    <Container
      noVideo={!videoUrl && !mobileVideoUrl}
      isResponsiveEmbed={isResponsiveEmbed}
      useDifferentVideoForMobile={useDifferentVideoForMobile}
    >
      {videoUrl ? (
        <VideoContainer
          className="main-video-container"
          isResponsiveEmbed={isResponsiveEmbed}
          onClick={() => !autoplay && setPlay(!play)}
        >
          <ReactPlayer
            className="react-player"
            url={videoUrl}
            playing={play}
            volume={0}
            muted={true}
            loop={true}
            playsinline={true}
            width="100%"
            height="100%"
          />

          {image && <BackgroundImage className="poster" image={image} />}

          {!autoplay && !play && videoType !== `youtube_url` && (
            <StyledIconButton>
              <PlayIcon />
            </StyledIconButton>
          )}
        </VideoContainer>
      ) : (
        <BackgroundImage image={image} />
      )}

      {mobileVideoUrl && useDifferentVideoForMobile && (
        <MobileVideoContainer
          isMobileResponsiveEmbed={isMobileResponsiveEmbed}
          className="mobile-video-container"
          // isResponsiveEmbed={isResponsiveEmbed}
          onClick={() => !autoplay && setPlay(!play)}
        >
          <ReactPlayer
            className="react-player"
            url={mobileVideoUrl}
            playing={play}
            volume={0}
            muted={true}
            loop={true}
            playsinline={true}
            width="100%"
            height="100%"
          />

          {/* {image && <BackgroundImage className="poster" image={image} />} */}

          {!autoplay && !play && videoType !== `youtube_url` && (
            <StyledIconButton>
              <PlayIcon />
            </StyledIconButton>
          )}
        </MobileVideoContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  ${({ noVideo }) =>
    noVideo &&
    css`
      height: 300px;
    `}

  ${({ isResponsiveEmbed }) =>
    !isResponsiveEmbed &&
    css`
      @media (min-width: 768px) {
        height: calc(100vh - 80px);
        max-height: 1080px;
        overflow: hidden;
      }
    `}

  .mobile-video-container {
    display: block;
    @media (min-width: 768px) {
      display: none;
    }
  }

  ${({ useDifferentVideoForMobile }) =>
    useDifferentVideoForMobile &&
    css`
      .main-video-container {
        display: none;
      }
      @media (min-width: 768px) {
        .main-video-container {
          display: block;
        }
      }
    `}
`

const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  ${({ isResponsiveEmbed }) =>
    !isResponsiveEmbed &&
    css`
      @media (min-width: 768px) {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        overflow: hidden;

        .react-player,
        video {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          max-height: 100%;
          z-index: 2;
          object-fit: cover;
        }
      }
    `}

  .poster {
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      object-position: 0% 0% !important;
    }
  }
`

const MobileVideoContainer = props => {
  const { isMobileResponsiveEmbed } = props

  if (isMobileResponsiveEmbed) {
    return <VideoContainer {...props} />
  } else {
    return <div {...props} />
  }
}

// const CroppingVideoContainer = styled.div`
//   @media (max-width: 767px) {
//     display: none;
//   }

//   position: absolute;
//   right: 0;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   height: 100%;
//   width: 100%;
//   z-index: 1;
//   overflow: hidden;

//   .react-player,
//   video,
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     max-height: 100%;
//     z-index: 2;
//     object-fit: cover;
//   }

//   .poster {
//     z-index: 1;
//     width: 100%;
//     height: 100%;

//     img {
//       object-position: 0% 0% !important;
//     }
//   }
// `

// const ResponsiveVideoContainer = styled.div`
//   position: relative;
//   padding-top: 56.25%;
//   .react-player {
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 2;
//   }

//   .poster {
//     z-index: 1;
//     width: 100%;
//     height: 100%;

//     img {
//       object-position: 0% 0% !important;
//     }
//   }
// `

const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background: transparent;

    svg {
      width: 120px !important;
      height: 120px !important;
      color: ${colors.primary};
    }
  }
`

export default Hero
