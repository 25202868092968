import React from "react"
import styled from "styled-components"

// import app components
import { Edges, Textarea, Button, Spacer } from "components"

const Text = props => {
  const { text, button, buttonStyle } = props

  return (
    <Container>
      <Edges size="md">
        {text && <Textarea content={text} />}

        {button && (
          <Spacer mt={20} mb={20}>
            <Button
              to={button.url}
              target={button.target}
              children={button.title}
              variant={buttonStyle}
              color="secondary"
            />
          </Spacer>
        )}
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding: 10px 0;
  margin: 10px 0;

  @media (min-width: 768px) {
    padding: 15px 0;
    margin: 20px 0;
  }
`

export default Text
