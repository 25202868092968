import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { useInView } from "react-intersection-observer"

// import app components
// import CurrencySwitcher from "components/currencySwitcher"
import ScrollToTop from "components/scrollToTop"
import Edges from "components/edges"
import FooterMenu from "../menu/FooterMenu"
import LegalMenu from "../menu/LegalMenu"
import NewsletterSignup from "./NewsletterSignup"

import { colors } from "theme"

export default () => {
  const [ref, inView] = useInView()

  return (
    <>
      <Footer ref={ref}>
        <ScrollToTop fixed={!inView} aria-hidden="true" />

        <NewsletterSignup />

        <Edges size="lg">
          <Grid container justify="center" alignItems="center">
            <FooterMenu />
            {/* <CurrencySwitcher /> */}
          </Grid>
        </Edges>
      </Footer>

      <Legal>
        <Edges size="lg">
          <Grid container justify="space-between" alignItems="center">
            <Title>&copy; Linda Meyer-Hentschel</Title>
            <Title>Vat. 11042310968</Title>
            <LegalMenu />
          </Grid>
        </Edges>
      </Legal>
    </>
  )
}

const Footer = styled.div`
  padding: 20px 0;
  background: #f4f4f4;
  color: ${colors.primary};
`

const Legal = styled.div`
  background: #fff;
  color: ${colors.primary};
`

const Title = styled(Typography)`
  margin-top: 20px;
  width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 0;
    width: auto;
  }
`
